import {NgModule} from '@angular/core';
import {CommonModule,NgOptimizedImage} from '@angular/common';
import {CustomMaterialModule} from './custom-material.module';
import {FormsModule,ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {GalleryModule} from 'ng-gallery';

@NgModule({
	declarations:[],
	imports:[
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		NgOptimizedImage,
		CustomMaterialModule,
		GalleryModule
	],
	exports:[
		CommonModule,
		RouterModule,
		FormsModule,
		ReactiveFormsModule,
		NgOptimizedImage,
		CustomMaterialModule,
		GalleryModule
	],
	providers:[]
})
export class SharedModule{
}
