import {afterNextRender,ApplicationRef,Inject,Injectable,Optional,PLATFORM_ID,REQUEST} from '@angular/core';
import {Title,Meta} from '@angular/platform-browser';
import {BehaviorSubject,firstValueFrom,map,merge,Observable,Subject,tap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApolloQueryResult} from '@apollo/client/core';
import {LocalStorage,LocalStorageService} from 'ngx-webstorage';
import {DOCUMENT,isPlatformServer} from '@angular/common';
import {UserResponseDto} from './authentication/dto/user-response.dto';
import {GetInitialDataQuery,GetInitialDataGQL} from './common/graphql/get-initial-data.generated';
import {AuthenticationService} from './authentication/authentication.service';
import {PayPalNamespace} from '@paypal/paypal-js';
import {GOOGLE_MAPS,GOOGLE_PLACES,MERCADO_PAGO,PAYPAL,PHONE_NUMBER_UTIL} from './shared/constants';
import {PhoneNumberUtil} from 'google-libphonenumber';
import {Loader} from '@googlemaps/js-api-loader';
import {environment} from '../environments/environment';
import {SearchProductsGQL,SearchProductsQuery,SearchProductsQueryVariables} from './common/graphql/search-products.generated';
import {GetCartGQL,GetCartQuery,GetCartQueryVariables} from './common/graphql/get-cart.generated';
import {CreateCartItemGQL,CreateCartItemMutation,CreateCartItemMutationVariables} from './common/graphql/create-cart-item.generated';
import {MutationResult} from 'apollo-angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CartItemCreatedSnackComponent} from './misc/components/cart-item-created-snack/cart-item-created-snack.component';


let hasCookieAccess=false;

async function checkHasCookieAccess(){
// 	// Check if Storage Access API is supported
// 	if (!document.requestStorageAccess) {
// 		// Storage Access API is not supported so best we can do is
// 		// hope it's an older browser that doesn't block 3P cookies.
// 		return true;
// 	}
//
// 	// Check if access has already been granted
// 	if (await document.hasStorageAccess()) {
// 		return true;
// 	}
//
// 	// Check the storage-access permission
// 	// Wrap this in a try/catch for browsers that support the
// 	// Storage Access API but not this permission check
// 	// (e.g. Safari and older versions of Firefox).
// 	let permission;
// 	try {
// 		permission = await navigator.permissions.query(
// 			// @ts-ignore
// 			{name: 'storage-access'}
// 		);
// 	} catch (error) {
// 		// storage-access permission not supported. Assume no cookie access.
// 		return false;
// 	}
//
// 	if (permission) {
// 		if (permission.state === 'granted') {
// 			// Permission has previously been granted so can just call
// 			// requestStorageAccess() without a user interaction and
// 			// it will resolve automatically.
// 			try {
// 				await document.requestStorageAccess();
// 				return true;
// 			} catch (error) {
// 				// This shouldn't really fail if access is granted, but return false
// 				// if it does.
// 				return false;
// 			}
// 		} else if (permission.state === 'prompt') {
// 			// Need to call requestStorageAccess() after a user interaction
// 			// (potentially with a prompt). Can't do anything further here,
// 			// so handle this in the click handler.
// 			return false;
// 		} else if (permission.state === 'denied') {
// 			// Currently not used. See:
// 			// https://github.com/privacycg/storage-access/issues/149
// 			return false;
// 		}
// 	}
//
	// By default return false, though should really be caught by one of above.
	return false;
}

@Injectable({
	providedIn:'root'
})
export class AppService{
	private readonly document:Document;
	private readonly request:Request;
	private readonly platformId:object;
	private readonly title:Title;
	private readonly meta:Meta;
	private readonly applicationRef:ApplicationRef;
	private readonly httpClient:HttpClient;
	private readonly getInitialData:Observable<GetInitialDataQuery>;
	private readonly localStorageService:LocalStorageService;
	private readonly authenticationService:AuthenticationService;
	private readonly themeSource:BehaviorSubject<string|undefined>;
	private readonly searchProductsGQL:SearchProductsGQL;
	private readonly cartSource:Subject<GetInitialDataQuery['getCart']&{itemsCount:number}>;
	private readonly getCartGQL:GetCartGQL;
	private readonly createCartItemGQL:CreateCartItemGQL;
	@LocalStorage('themeId')
	private themeId?:string;
	@LocalStorage('cartId')
	private cartId?:string;
	private matSnackBar:MatSnackBar;
	theme:Observable<string|undefined>;
	user:Observable<UserResponseDto|null|undefined>;
	productCategories:Observable<GetInitialDataQuery['getProductCategories']>;
	stores:Observable<GetInitialDataQuery['getStores']>;
	cart:Observable<GetInitialDataQuery['getCart']&{itemsCount:number}>;
	orderStatuses:Observable<GetInitialDataQuery['getOrderStatuses']>;
	phoneNumberUtil?:PhoneNumberUtil;
	paypal?:PayPalNamespace;
	googleMaps?:google.maps.MapsLibrary;
	googlePlaces?:google.maps.PlacesLibrary;
	mercadoPago?:any;
	
	constructor(
		title:Title,
		meta:Meta,
		applicationRef:ApplicationRef,
		httpClient:HttpClient,
		getInitialDataGQL:GetInitialDataGQL,
		localStorageService:LocalStorageService,
		@Inject(DOCUMENT) document:Document,
		@Optional() @Inject(REQUEST) request:Request,
		@Inject(PLATFORM_ID) platformId:object,
		authenticationService:AuthenticationService,
		searchProductsGQL:SearchProductsGQL,
		getCartGQL:GetCartGQL,
		createCartItemGQL:CreateCartItemGQL,
		matSnackBar:MatSnackBar,
		@Optional() @Inject(PHONE_NUMBER_UTIL) phoneNumberUtil:PhoneNumberUtil,
		@Optional() @Inject(PAYPAL) paypal:Promise<PayPalNamespace>,
		@Optional() @Inject(GOOGLE_MAPS) googleMaps:Promise<Loader>,
		@Optional() @Inject(GOOGLE_PLACES) googlePlaces:Promise<Loader>,
		@Optional() @Inject(MERCADO_PAGO) loadMercadoPago:()=>Promise<unknown>
	){
		this.document=document;
		this.request=request;
		this.platformId=platformId;
		this.title=title;
		this.meta=meta;
		this.applicationRef=applicationRef;
		this.httpClient=httpClient;
		this.getInitialData=getInitialDataGQL
		.fetch({cartId:this.cartId},{})
		.pipe(
			map((result:ApolloQueryResult<GetInitialDataQuery>):GetInitialDataQuery=>{
				return result.data;
			}),
			tap((result:GetInitialDataQuery):void=>{
				if(result.getCart){
					this.cartId=result.getCart.id;
					let itemsCount:number=0;
					if(result.getCart.items) itemsCount=result.getCart.items.reduce((previousValue:number,currentValue:{quantity:number}):number=>previousValue+currentValue.quantity,0);
					this.cartSource.next({...result.getCart,itemsCount});
				}
			})
		);
		this.authenticationService=authenticationService;
		this.searchProductsGQL=searchProductsGQL;
		this.getCartGQL=getCartGQL;
		this.createCartItemGQL=createCartItemGQL;
		this.matSnackBar=matSnackBar;
		this.themeSource=new BehaviorSubject<string|undefined>(this.themeId);
		this.localStorageService=localStorageService;
		
		this.theme=this.themeSource.asObservable()
		.pipe(
			map((value:string|undefined):string=>{
				this.document.body.classList.remove('app-dark-theme');
				this.document.body.classList.remove('app-light-theme');
				if(!value){
					this.localStorageService.clear('themeId');
					value='app-light-theme';
				}
				this.document.body.classList.add(value);
				return value;
			})
		);
		this.user=this.authenticationService.userSource.asObservable();
		this.productCategories=this.getInitialData.pipe(
			map((result:GetInitialDataQuery):GetInitialDataQuery['getProductCategories']=>result.getProductCategories)
		);
		this.stores=this.getInitialData.pipe(
			map((result:GetInitialDataQuery):GetInitialDataQuery['getStores']=>result.getStores)
		);
		this.cartSource=new Subject<GetInitialDataQuery['getCart']&{itemsCount:number}>();
		this.cart=this.cartSource.asObservable();
		this.orderStatuses=this.getInitialData.pipe(
			map((result:GetInitialDataQuery):GetInitialDataQuery['getOrderStatuses']=>result.getOrderStatuses)
		);
		
		afterNextRender(async():Promise<void>=>{
			this.phoneNumberUtil=phoneNumberUtil;
			paypal.then((paypal:PayPalNamespace):void=>{
				this.paypal=paypal;
			});
			googleMaps.then(async(googleMaps:Loader):Promise<void>=>{
				this.googleMaps= await googleMaps.importLibrary('maps');
			});
			googlePlaces.then(async(googlePlaces:Loader):Promise<void>=>{
				this.googlePlaces= await googlePlaces.importLibrary('places');
			});
			await loadMercadoPago();
			// @ts-expect-error window.MercadoPago
			this.mercadoPago=new window.MercadoPago(environment.mercadopago.publicKey);
			console.log('mercadoPago',this.mercadoPago);
			if(this.document.defaultView){
				const darkColorScheme:MediaQueryList=this.document.defaultView.matchMedia('(prefers-color-scheme: dark)');
				darkColorScheme.addEventListener('change',():void=>{
					if(!this.themeId){
						this.themeSource.next(darkColorScheme.matches ? 'app-dark-theme' : 'app-light-theme');
						this.applicationRef.tick();
					}
				});
				setTimeout(():void=>{
					darkColorScheme.dispatchEvent(new MediaQueryListEvent('change'));
				},1000);
				
				hasCookieAccess= await checkHasCookieAccess();
				console.log('hasCookieAccess',hasCookieAccess);
			}
		});
		
		
	}
	
	updateSeo(title:string,description?:string,keywords?:string,photo?:string):void{
		this.title.setTitle(title);
		this.meta.updateTag({content:title},'property="og:title"');
		if(isPlatformServer(this.platformId) && this.request){
			this.meta.updateTag({content:this.request.url},'property="og:url"');
		}else{
			this.meta.updateTag({content:this.document.location.href},'property="og:url"');
		}
		if(description){
			this.meta.updateTag({content:description},'name="description"');
			this.meta.updateTag({content:description},'property="og:description"');
		}
		if(keywords){
			this.meta.updateTag({content:keywords},'name="keywords"');
		}
		if(photo){
			this.meta.updateTag({content:photo},'property="og:image"');
		}
	}
	
	toggleDarkTheme():void{
		if(!this.themeSource.value){
			this.themeSource.next('app-dark-theme');
			this.themeId='app-dark-theme';
		}else if(this.themeSource.value==='app-light-theme'){
			this.themeSource.next('app-dark-theme');
			this.themeId='app-dark-theme';
		}else if(this.themeSource.value==='app-dark-theme'){
			this.themeSource.next('app-light-theme');
			this.themeId='app-light-theme';
		}
	}
	
	
	searchProducts(variables:SearchProductsQueryVariables):Observable<SearchProductsQuery['searchProducts']>{
		return this.searchProductsGQL
		.fetch(variables,{})
		.pipe(
			map(
				(result:ApolloQueryResult<SearchProductsQuery>):SearchProductsQuery['searchProducts']=>{
					if(!result.data?.searchProducts) return [];
					return result.data.searchProducts;
				}
			)
		);
	}
	
	getCart(variables:GetCartQueryVariables):Observable<GetCartQuery['getCart']>{
		return this.getCartGQL
		.fetch(variables,{})
		.pipe(
			map(
				(result:ApolloQueryResult<GetCartQuery>):NonNullable<GetCartQuery['getCart']>=>{
					if(!result.data?.getCart) throw new Error('data not found');
					return result.data.getCart;
				}
			),
			tap(
				(result:NonNullable<GetCartQuery['getCart']>):void=>{
					this.cartId=result.id;
					let itemsCount:number=0;
					if(result.items) itemsCount=result.items.reduce((previousValue:number,currentValue:{quantity:number}):number=>previousValue+currentValue.quantity,0);
					this.cartSource.next({...result,itemsCount});
				}
			)
		);
	}
	
	createCartItem(variables:CreateCartItemMutationVariables):Observable<CreateCartItemMutation['createCartItem']>{
		return this.createCartItemGQL
		.mutate(variables,{})
		.pipe(
			map((result:MutationResult<CreateCartItemMutation>):CreateCartItemMutation['createCartItem']=>{
				if(!result.data?.createCartItem) throw new Error('data not found');
				return result.data.createCartItem;
			}),
			tap(():void=>{
				this.matSnackBar.openFromComponent(CartItemCreatedSnackComponent);
			})
		);
	}
	
}
