import * as Types from './types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type CreateCartItemMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  product: Types.Scalars['String']['input'];
  quantity: Types.Scalars['Float']['input'];
}>;


export type CreateCartItemMutation = { __typename?: 'Mutation', createCartItem: { __typename?: 'CartItem', createdAt: any, id: string, quantity: number, updatedAt: any, product: { __typename?: 'Product', id: string, name: string } } };

export const CreateCartItemDocument = gql`
    mutation createCartItem($id: String!, $product: String!, $quantity: Float!) {
  createCartItem(id: $id, product: $product, quantity: $quantity) {
    createdAt
    id
    quantity
    product {
      id
      name
    }
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class CreateCartItemGQL extends Apollo.Mutation<CreateCartItemMutation, CreateCartItemMutationVariables> {
    document = CreateCartItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }