import * as Types from './types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type GetInitialDataQueryVariables = Types.Exact<{
  cartId?: Types.InputMaybe<Types.Scalars['String']['input']>;
}>;


export type GetInitialDataQuery = { __typename?: 'Query', getProductCategories: Array<{ __typename?: 'ProductCategory', createdAt: any, label: string, id: string, updatedAt: any, slug: string }>, getCart?: { __typename?: 'Cart', createdAt: any, id: string, updatedAt: any, items?: Array<{ __typename?: 'CartItem', quantity: number, product: { __typename?: 'Product', name: string, quantity: number, price: number, discountPercent?: number | null, id: string, slug: string, pictures: Array<{ __typename?: 'ProductPicture', small: string }> } }> | null } | null, getStores: Array<{ __typename?: 'Store', city: string, country: string, createdAt: any, id: string, line1: string, line2?: string | null, name: string, phone: string, placeId?: string | null, state: string, updatedAt: any, zip: string, location: { __typename?: 'StoreLocation', coordinates: Array<number>, type: string } }>, getOrderStatuses: Array<{ __typename?: 'OrderStatus', updatedAt: any, id: string, createdAt: any, label: string, icon: string, order?: number | null, description: string }> };

export const GetInitialDataDocument = gql`
    query getInitialData($cartId: String) {
  getProductCategories {
    createdAt
    label
    id
    updatedAt
    slug
  }
  getCart(id: $cartId) {
    createdAt
    id
    items {
      product {
        name
        quantity
        pictures {
          small
        }
        price
        discountPercent
        id
        slug
      }
      quantity
    }
    updatedAt
  }
  getStores {
    city
    country
    createdAt
    id
    line1
    line2
    location {
      coordinates
      type
    }
    name
    phone
    placeId
    state
    updatedAt
    zip
  }
  getOrderStatuses {
    updatedAt
    id
    createdAt
    label
    icon
    order
    description
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class GetInitialDataGQL extends Apollo.Query<GetInitialDataQuery, GetInitialDataQueryVariables> {
    document = GetInitialDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }