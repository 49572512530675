import {Component} from '@angular/core';

@Component({
	selector:'app-cart-item-created-snack',
	imports:[],
	templateUrl:'./cart-item-created-snack.component.html',
	styleUrl:'./cart-item-created-snack.component.scss'
})
export class CartItemCreatedSnackComponent{

}
