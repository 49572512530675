import * as Types from './types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type SearchProductsQueryVariables = Types.Exact<{
  limit: Types.Scalars['Float']['input'];
  skip: Types.Scalars['Float']['input'];
  query: Types.Scalars['String']['input'];
}>;


export type SearchProductsQuery = { __typename?: 'Query', searchProducts: Array<{ __typename?: 'Product', description: string, id: string, name: string, price: number, slug: string, discountPercent?: number | null, searchHighlights?: string | null, pictures: Array<{ __typename?: 'ProductPicture', large: string, small: string }> }> };

export const SearchProductsDocument = gql`
    query searchProducts($limit: Float!, $skip: Float!, $query: String!) {
  searchProducts(limit: $limit, skip: $skip, query: $query) {
    description
    id
    name
    pictures {
      large
      small
    }
    price
    slug
    discountPercent
    searchHighlights
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class SearchProductsGQL extends Apollo.Query<SearchProductsQuery, SearchProductsQueryVariables> {
    document = SearchProductsDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }